import { apiDelete, apiGet, apiPatch, apiPost } from './apis';
import { apiGetV2 } from './apisv2';

export const createUpdatePostApi = async (data: Object, id: any) => {
  if (id) {
    return apiPatch(`statuses/${id}`, null, data);
  }

  return apiPost('statuses', null, data);
};

export const getListPostApi = async (params: Object) => {
  return apiGet('timelines/home', params, null);
};

export const getListMomentApi = async (params: Object) => {
  return apiGet('timelines/moment', params, null);
};

export const getListPostFriendDiscoverApi = async (params: Object) => {
  return apiGet('timelines/friend', params, null);
};

export const getListWatchApi = async (params: Object) => {
  return apiGet('timelines/watch', params, null);
};

export const getDiscoverMomentApi = async (params: Object) => {
  return apiGet('suggestions/moment', params, null);
};

export const getDiscoverWatchApi = async (params: Object) => {
  return apiGet('suggestions/watch', params, null);
};

export const postWatchHistoriesApi = async (data: Object) => {
  return apiPost('watch_histories', null, data);
};

export const getMentionsPost = async id => {
  return apiGet(`statuses/${id}/mentions`, null, null);
};

export const getListPostTagApi = async (tag, params) => {
  return apiGet(`timelines/tag/${tag}`, params, null);
};

export const getPreviewUrlApi = async (params: Object) => {
  return apiGet('fetch-url/grab', params, null);
};

export const getPostMediaApi = async (id: any) => {
  return apiGet(`media/${id}`, null, null);
};

export const getListPostAccountApi = async (id: any, params: Object) => {
  return apiGet(`accounts/${id}/statuses`, { ...params, id: null }, null);
};

export const deletePostApi = async (id: any) => {
  return apiDelete(`statuses/${id}`, null, null);
};

export const reactionPostApi = async (id: any, data: any) => {
  return apiPost(`statuses/${id}/favourite`, null, data);
};

export const getPostDetailApi = async (id: any, params: any = null) => {
  return apiGet(`statuses/${id}`, params, null);
};

export const hiddenPostDetailApi = async (id: any, data: any) => {
  return apiPatch(`statuses/${id}`, null, data);
};

export const unReactionPostApi = async (id: any, data: any) => {
  return apiPost(`statuses/${id}/unfavourite`, null, data);
};

export const pinPostApi = async (id: any) => {
  return apiPost(`statuses/${id}/pin`, null, null);
};

export const unPinPostApi = async (id: any) => {
  return apiPost(`statuses/${id}/unpin`, null, null);
};

export const reblogPostApi = async (id: any) => {
  return apiPost(`statuses/${id}/reblog`, null, null);
};

export const getReactionById = async (id: string, data: any) => {
  return apiPost(`statuses/${id}/reaction_post`, data, null);
};
export const getReblogPostApi = async (id: any, params) => {
  return apiGet(`statuses/${id}/reblog`, params, null);
};

export const getBookmarkPostApi = async params => {
  return apiGet('bookmarks', params, null);
};

export const bookmarkPostApi = async (id: any) => {
  return apiPost(`statuses/${id}/bookmark`, null, null);
};

export const unbookmarkPostApi = async (id: any) => {
  return apiPost(`statuses/${id}/unbookmark`, null, null);
};

export const mutePostApi = async (id: any) => {
  return apiPost(`statuses/${id}/mute`, null, null);
};

export const unmutePostApi = async (id: any) => {
  return apiPost(`statuses/${id}/unmute`, null, null);
};

export const embedPostApi = async (data: any) => {
  return apiPost('web/embed', null, null);
};

export const votePostApi = async (id: any, data: any) => {
  return apiPost(`polls/${id}/votes`, null, data);
};

export const voteChangeApi = async (id: any, data: any) => {
  return apiPatch(`polls/${id}/votes/123`, null, data);
};

export const reportPostApi = async (data: any) => {
  return apiPost('reports', null, data);
};

export const getPeopleVotePostApi = async (id: any, params: any) => {
  return apiGet(`polls/${id}/list_account`, params, null);
};

export const getCommentPostApi = async (id: any, params: any) => {
  return apiGet(`comments/${id}`, params, null);
};

export const getReactionPeopleApi = async (id: any, params: any) => {
  return apiGet(`statuses/${id}/favourited_by`, params, null);
};

export const getLocationApi = async (params: any) => {
  return apiGet('places', params, null);
};

export const getCityApi = async (params: any) => {
  return apiGet('provinces', params, null);
};
export const getCityApiUpdate = async (params: any) => {
  return apiGet('list_provinces', params, null);
};
export const getEventLifeApi = async () => {
  return apiGet('life_event_categories', null, null);
};

export const getGifApi = async (params: any) => {
  return apiGet('gifts', params, null);
};

export const getBgColorApi = async () => {
  return apiGet('status_background_color', null, null);
};

export const createMediaMastodon = async (params: any) => {
  return apiPost(`sync_media`, null, params);
};

export const getListPostTags = async (idUser: any, params: any) => {
  return apiGet(`/accounts/${idUser}/mentions_statuses`, params, null);
};
export const updatePostTags = async (idUser: any, params: any) => {
  return apiPatch(`/accounts/${idUser}/update_mentions_statuses`, params, null);
};
export const crudBookmarkApi = async (
  type,
  params: any,
  data: any,
  id,
  source: any
) => {
  if (type === 'POST') {
    return apiPost(`bookmarks`, params, data);
  }

  if (type === 'GET') {
    return apiGet(`bookmarks`, params, data);
  }

  return apiDelete(`bookmarks/${id}`, params, data);
};

export const crudBookmarkCollectionApi = async (type, id, data: any) => {
  if (type === 'POST') {
    return apiPost(`bookmark_collections`, null, data);
  }

  if (type === 'GET') {
    return apiGet(`bookmark_collections`, null, data);
  }

  if (type === 'PATCH') {
    return apiPatch(`bookmark_collections/${id}`, null, data);
  }

  return apiDelete(`bookmark_collections/${id}`, null, data);
};

export const searchVideos = async (key: any, type: any, offset?: any) => {
  const offsetQuery = offset !== undefined ? `&offset=${offset}` : '';
  return apiGetV2(
    `search?q=${key}${offsetQuery}&type[]=statuses&status_type=${type}`,
    null,
    null
  );
};

export const getSearchMoment = async (key: any, params: any) => {
  return apiGetV2(
    `search?q=${key}&type[]=statuses&type[]=accounts&type[]=pages&type[]=groups&status_type=moment`,
    params,

    null
  );
};
export const searchGroupName = async (key: any, params: any) => {
  return apiGetV2(`search?q=${key}&type=groups`, params, null);
};

export const searchPostInGroup = async (key: any, params: any) => {
  return apiGetV2(
    `search?q=${key}&type[]=statuses&in_group=true`,
    params,
    null
  );
};
export const getDetailBookmarkCollection = async (id: any) => {
  return apiGet(`bookmark_collections/${id}`, null, null);
};

export const hideTagUser = async (id: any) => {
  return apiPatch(`statuses/${id}/unmentions`, null, null);
};
export const hideBookmark = async (id: any) => {
  return apiDelete(`bookmarks/${id}`, null, null);
};

export const getListOfUsersCommentingOnPosts = async (id: any) => {
  return apiGet(`statuses/${id}/comment_display_name`, null, null);
};

export const getListWatchShows = async () => {
  return apiGet('suggestions/series', null, null);
};
export const getListWatchShowFriend = async () => {
  return apiGet('series_friend_follow', null, null);
};
export const getDetailWatchShow = async (id: any) => {
  return apiGet(`series/${id}`, null, null);
};
export const unFollowSeries = async (id: any) => {
  return apiPost(`series/${id}/unfollow`, null, null);
};
export const FollowSeries = async (id: any) => {
  return apiPost(`series/${id}/follow`, null, null);
};

export const shortcutListApi = async (method, data) => {
  if (method === 'POST') {
    return apiPost('shortcuts', null, data);
  }

  return apiGet('shortcuts', null, data);
};

export const getReactionsLogs = async (params: any) => {
  return apiGet(`reactions_logs`, params, null);
};

export const getStatusLogs = async (params: any) => {
  return apiGet(`status_logs`, params, null);
};
export const getBookMarkCollection = async (params: any) => {
  return apiGet(`bookmark_collections_logs`, params, null);
};

export const getStatusBookMarkLog = async (params: any) => {
  return apiGet(`status_bookmarks_logs`, params, null);
};

export const deleteBookMarkCollection = async (id: string) => {
  return apiDelete(`bookmark_collections/${id}`, null, null);
};
export const getPageLogs = async (params: any) => {
  return apiGet(`pages_logs`, params, null);
};
